export default class FaqCategory {

    id = 0;
    title = null;
    mainTitle = '';
    isDeleted = false;
    faqMainCategoryId = 0;

    constructor(data) {
        if (!data) {
            return;
        }
        this.id = data.id;
        this.title = data.title;
        this.mainTitle = data.title[process.env.VUE_APP_I18N_LOCALE];
        this.isDeleted = data.deleted_at !== null;
        this.faqMainCategoryId = data.faq_main_category_id;
    }
}