<template>
  <b-row v-if="$store.state.currentUser.isFaqAdmin">
    <b-col>
      <div class="d-flex align-items-center w-100 mx-3">
        <h5 class="text-uppercase p-0 my-3 flex-grow-1">
          {{ $t("admin.edit-faq") }}
        </h5>
      </div>
      <b-form-row class="border-bottom sticky-top page-header bg-white mt-2" v-for="(value, name) in form.title" :key="'title-'+name">
        <b-form-group
            :label="$t('admin.title') + ' ' + name"
            class="mb-0 w-100"
        >
          <b-form-input
              :required="true"
              v-model="form.title[name]"
          ></b-form-input>
        </b-form-group>
        </b-form-row>
      <b-form-row class="border-bottom sticky-top page-header bg-white mt-2" v-for="(value, name) in form.body" :key="'body-'+name">
        <b-form-group
            :label="$t('admin.body') + ' ' + name"
            class="mb-0 w-100"
            rows="7"
        >
          <b-form-textarea
              :required="true"
              class="textarea-height"
              v-model="form.body[name]"
          ></b-form-textarea>
        </b-form-group>

      </b-form-row>
      <b-form-row class="mt-3">
        <b-button
            @click="handleOk"
            variant="success">
          {{$t('general.save')}}
        </b-button>
      </b-form-row>
    </b-col>
  </b-row>
</template>
<script>
import Faq from "../../../models/Faq";

export default {
  data: function () {
    return {
      form: {title:{}, body:{}, faq_category_id: 0},
      isEdit: false,
      editId: 0
    };
  },
  computed: {
    selectedFaqMainCategoryId() {
      return this.$route.params.faqMainCategory;
    },
    selectedFaqCategoryId() {
      return this.$route.params.faqCategory;
    },
    selectedFaqId() {
      return this.$route.params.faq;
    },
  },
  mounted() {
    if(this.selectedFaqId !== undefined && this.selectedFaqId !== null){
      this.loadFaq();
    }else{
      this.loadEmptyFaq();
    }
  },
  methods: {
    loadFaq() {
      let url = "/faq-main-categories/" + this.selectedFaqMainCategoryId +"/faq-categories/" + this.selectedFaqCategoryId +"/faqs/"+this.selectedFaqId;
      this.$aGet(
          url,
          (data) => {
            let faq = new Faq(data);
            this.form.title = faq.title;
            this.form.body = faq.body;
            this.form.faq_category_id = this.selectedFaqCategoryId;
            this.editId = faq.id;
            this.isEdit = true;
          },
          (errors) => {
            console.log(errors);
          }
      );
    },
    loadEmptyFaq(){
      this.form.title = this.$getEmptyLocaleObject();
      this.form.body = this.$getEmptyLocaleObject();
      this.form.faq_category_id = this.selectedFaqCategoryId;
      this.editId = 0;
      this.isEdit = false;
    },
    handleOk() {
      // Check if any input field is empty
      if (Object.values(this.form.title).some(field => field === '')) {
        // At least one field is empty, do not proceed
        return;
      }
      if (Object.values(this.form.body).some(field => field === '')) {
        // At least one field is empty, do not proceed
        return;
      }
      if(this.editId === 0){
        this.add();
      }else{
        this.edit();
      }
    },
    add() {
      this.$aPost(
          "/faq-main-categories/" + this.selectedFaqMainCategoryId +"/faq-categories/" + this.selectedFaqCategoryId +"/faqs",
          this.form,
          () => {
            this.$router.push("/admin/faq-main-categories/" + this.selectedFaqMainCategoryId +"/faq-categories/" + this.selectedFaqCategoryId +"/faq");
          },
          (errors) => {
            console.log(errors);
          }
      );
    },
    edit() {
      this.$aPost(
          "/faq-main-categories/" + this.selectedFaqMainCategoryId +"/faq-categories/" + this.selectedFaqCategoryId +"/faqs/"+this.editId,
          this.form,
          () => {
            this.$router.push("/admin/faq-main-categories/" + this.selectedFaqMainCategoryId +"/faq-categories/" + this.selectedFaqCategoryId +"/faq");
          },
          (errors) => {
            console.log(errors);
          }
      );
    },
  },
};
</script>

<style scoped>
.textarea-height {
  height: 150px; /* Adjust the height as per your requirement */
}
</style>
