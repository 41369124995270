export default class Verification {

    static STATUS_PENDING = 0;
    static STATUS_APPROVED = 1;
    static STATUS_DECLINED = 2;

    id = 0;
    status = Verification.TYPE_PENDING;
    photo = null;
    info = {};
    country = null;
    type = null;

    constructor(data) {
        this.id = data.id;
        this.status = data.status == Verification.STATUS_PENDING ? 'pending' : data.status == Verification.STATUS_DECLINED ? 'declined' : 'approved' ;
        this.name = data.info.last_name + ' ' + data.info.first_name;
        this.verification_media = data.verification_media;
        this.info = data.info;
        this.type = data.type;
        this.country = data.country;
    }

    get isPending() {
        return this.status == Verification.STATUS_PENDING;
    }

    get isDeclined() {
        return this.status == Verification.STATUS_DECLINED;
    }
}