<template>
  <b-row v-if="$store.state.currentUser.isFaqAdmin">
    <b-col>
      <b-row class="border-bottom sticky-top page-header bg-white">
        <div class="d-flex align-items-center w-100 mx-3">
          <h5 class="text-uppercase p-0 my-3 flex-grow-1">
            {{ $t("admin.faq-categories") }}
          </h5>
          <b-nav pills>
            <b-nav-form class="mr-2">
              <b-form-input
                  :placeholder="$t('admin.search')"
                  v-model="q"
                  class="mr-1"
              ></b-form-input>
            </b-nav-form>
            <b-nav-item >
              <b-button @click="openAddNew()" variant="success">{{$t('admin.add-new')}}</b-button>
            </b-nav-item>
          </b-nav>
        </div>
      </b-row>
      <b-row>
        <b-table
            id="table"
            hover
            ref="table"
            responsive
            :items="loadFaqCategories"
            :fields="fields"
            per-page="0"
            class="small nowrap"
            :current-page="page"
        >
          <template #cell(title)="data">
            <span v-html="data.item.mainTitle"></span>
          </template>
          <template #cell(actions)="data">
            <div >
              <b-button
                  v-if="!data.item.isDeleted"
                  :to="'/admin/faq-main-categories/' + selectedFaqMainCategoryId + '/faq-categories/'+data.item.id+'/faq'"
                  class="mr-1"
                  variant="success"
                  size="sm"
              >{{ $t("admin.open") }}</b-button
              >
              <b-button
                  variant="primary"
                  class="mr-1"
                  size="sm"
                  @click="openEdit(data.item.title, data.item.id)"
              >{{ $t("admin.edit") }}</b-button
              >
              <b-button
                  v-if="!data.item.isDeleted"
                  @click.prevent="del(data.item)"
                  variant="danger"
                  class="mr-1"
                  size="sm"
              >{{ $t("admin.delete") }}</b-button
              >
              <b-button
                  v-else
                  @click.prevent="restore(data.item)"
                  variant="warning"
                  class="mr-1"
                  size="sm"
              >{{ $t("admin.restore") }}</b-button
              >
            </div>
          </template>
        </b-table>
      </b-row>
      <b-row class="text-center m-3 d-block" v-if="total > 0">
        <b-pagination
            align="center"
            v-model="page"
            :total-rows="total"
            :per-page="perPage"
            aria-controls="table"
        ></b-pagination>
      </b-row>
      <b-modal
          v-model="modalShow"
          id="EditFaqCategory"
          centered
          :title="$t('admin.edit-faq-category')"
          @ok="handleOk()"
      >
        <b-form-group
            v-for="(value, name) in form.title"
            :label="$t('admin.title') + ' ' + name"
            class="mb-0"
            :key="name"
        >
          <b-form-input
              :required="true"
              v-model="form.title[name]"
          ></b-form-input>
        </b-form-group>
      </b-modal>
    </b-col>
  </b-row>
</template>
<script>
import FaqCategory from "../../../models/FaqCategory";

export default {
  data: function () {
    return {
      q: "",
      page: 1,
      total: 0,
      perPage: 1,
      fields: [
        { key: "id", label: this.$t("admin.id") },
        { key: "title", label: this.$t("admin.title") },
        { key: "actions", label: this.$t("admin.actions"), tdClass: "full" },
      ],
      form: {title:{}, faq_main_category_id: 0},
      modalShow: false,
      isEdit: false,
      editId: 0
    };
  },
  watch: {
    q() {
      this.reset();
    },
    selectedFaqMainCategoryId() {
      this.reset();
    },
  },
  computed: {
    selectedFaqMainCategoryId() {
      return this.$route.params.faqMainCategory;
    },
  },
  methods: {
    openAddNew() {
      this.form.title = this.$getEmptyLocaleObject();
      this.form.faq_main_category_id = this.selectedFaqMainCategoryId;
      this.editId = 0;
      this.isEdit = false;
      this.modalShow = true;
    },
    openEdit(title, id) {
      this.form.title = title;
      this.form.faq_main_category_id = this.selectedFaqMainCategoryId;
      this.editId = id;
      this.isEdit = true;
      this.modalShow = true;
    },
    reset() {
      this.page = 1;
      this.$refs.table.refresh();
    },
    loadFaqCategories(ctx, callback) {
      let url = "/faq-main-categories/" + this.selectedFaqMainCategoryId +"/faq-categories";
      url += "?page=" + ctx.currentPage + "&q=" + this.q;
      this.$aGet(
          url,
          (data) => {
            let faqCategories = [];
            for (let obj of data.data) {
              const faqCategory = new FaqCategory(obj);
              faqCategories.push(faqCategory);
            }
            this.total = data.total;
            this.perPage = data.per_page;
            callback(faqCategories);
          },
          (errors) => {
            console.log(errors);
          }
      );
    },
    handleOk() {
      // Check if any input field is empty
      if (Object.values(this.form.title).some(field => field === '')) {
        // At least one field is empty, do not proceed
        return;
      }
      if(this.editId === 0){
        this.add();
      }else{
        this.edit();
      }
    },
    add() {
      this.$aPost(
          "/faq-main-categories/" + this.selectedFaqMainCategoryId +"/faq-categories",
          this.form,
          () => {
            this.$refs.table.refresh();
          },
          (errors) => {
            console.log(errors);
          }
      );
    },
    edit() {
      this.$aPost(
          "/faq-main-categories/" + this.selectedFaqMainCategoryId +"/faq-categories/" + this.editId,
          this.form,
          () => {
            this.$refs.table.refresh();
          },
          (errors) => {
            console.log(errors);
          }
      );
    },
    del(faqCategory) {
      this.$aPost(
          "/faq-main-categories/" + this.selectedFaqMainCategoryId +"/faq-categories/" + faqCategory.id,
          {
            _method: "DELETE",
          },
          () => {
            this.$refs.table.refresh();
          },
          (errors) => {
            console.log(errors);
          }
      );
    },
    restore(faqCategory) {
      this.$aPost(
          "/faq-main-categories/" + this.selectedFaqMainCategoryId +"/faq-categories/" + faqCategory.id + "/restore",
          {},
          () => {
            this.$refs.table.refresh();
          },
          (errors) => {
            console.log(errors);
          }
      );
    },
  },
};
</script>
