<template>
  <div>
    <b-container v-if="data !== null">
      <b-row>
        <b-col lg="3" md="2" sm="2" class="d-none d-sm-block">
          <b-nav vertical class="sticky-top">
            <b-nav-item to="/help" link-classes="m-0 py-0">
              <h5 class="text-uppercase p-0 mx-0 my-3">
                <span class="d-none d-lg-block">
                  {{ $t("admin.faq") }}
                </span>
              </h5>
            </b-nav-item>
            <b-nav-item @click="scrollTo('maincat-'+mainCatIndex)" exact v-for="(mainCat, mainCatIndex) in data" :key="'menumaincat-'+mainCatIndex">
              <span class="d-none d-lg-inline ml-3">{{
                  mainCat.actual_title
                }}</span>
            </b-nav-item>
          </b-nav>
        </b-col>
        <b-col sm="9" cols="12" class="border-left border-right content">
          <div v-if="faq === null">
              <div v-for="(mainCat, mainCatIndex) in data" :key="'maincat-'+mainCatIndex" :id="'maincat-'+mainCatIndex" >
                <h1>{{mainCat.actual_title}}</h1>
                <div v-for="(cat, catIndex) in mainCat.faq_categories" :key="'cat-'+catIndex" :id="'cat-'+catIndex">
                  <h3>{{cat.actual_title}}</h3>
                  <b-row>
                    <b-col lg="6" md="6" sm="12" v-for="(faq, faqIndex) in cat.faqs" :key="'faq-'+faqIndex" :id="'faq-'+faqIndex">
                      <a class="faq-link" @click="selectFaq(faq)">{{faq.actual_title}}</a>
                    </b-col>
                  </b-row>
                  <hr>
                </div>
              </div>
          </div>
          <div v-else>
            <div v-html="faq.mainBody"></div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<style scoped lang="scss">
@import "~@/assets/scss/_variables.scss";
@include media-breakpoint-down(md) {
  .nav {
    text-align: center;
  }
}
@include media-breakpoint-up(lg) {
  .nav {
    text-align: left;
  }
}
a.nav-link {
  font-size: 120%;
  padding: 0.75rem 1rem;
}
.icon {
  position: relative;
}
.footer {
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1001;
}
.spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000;
}

.faq-link {
  cursor: pointer;
  color: #219ebc;
}

.faq-link:hover {
  color: #16677b;
}
</style>
<script>
import Faq from "../models/Faq";
export default {
  data: function () {
    return {
      faq: null,
      data: null,
      collapseStates: {},
    };
  },
  mounted() {
    this.$get(
        "/faqs",
        (data) => {
          this.data = data.data;
        },
        () => {}
    );
  },
  methods: {
    selectFaq(faq){
      this.faq = new Faq(faq);
    },
    unSelectFaq() {
      this.faq = null;
    },
    scrollTo(elementId) {
      this.unSelectFaq();
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    },
  },
};
</script>
