<template>
  <page-payouts-main/>

</template>
<script>
import PagePayoutsMain from "./PagePayoutsMain.vue";
export default {
  data: function () {
    return {
      verification: null,
      isLoaded: false,
    };
  },
  components: {
    PagePayoutsMain,
  },
  computed: {
  },
  mounted() {

  },
  methods: {

  },
};
</script>
