<template>
  <b-row v-if="$store.state.currentUser.isFaqAdmin">
    <b-col>
      <b-row class="border-bottom sticky-top page-header bg-white">
        <div class="d-flex align-items-center w-100 mx-3">
          <h5 class="text-uppercase p-0 my-3 flex-grow-1">
            {{ $t("admin.faq") }}
          </h5>
          <b-nav pills>
            <b-nav-form class="mr-2">
              <b-form-input
                  :placeholder="$t('admin.search')"
                  v-model="q"
                  class="mr-1"
              ></b-form-input>
            </b-nav-form>
            <b-nav-item >
              <b-button
                  style="color: white;"
                  :to="'/admin/faq-main-categories/' + selectedFaqMainCategoryId + '/faq-categories/'+selectedFaqCategoryId+'/faq/add'"
                  variant="success">
                {{$t('admin.add-new')}}
              </b-button>
            </b-nav-item>
          </b-nav>
        </div>
      </b-row>
      <b-row>
        <b-table
            id="table"
            hover
            ref="table"
            responsive
            :items="loadFaq"
            :fields="fields"
            per-page="0"
            class="small nowrap"
            :current-page="page"
        >
          <template #cell(title)="data">
            <span v-html="data.item.mainTitle"></span>
          </template>
          <template #cell(actions)="data">
            <div >
              <b-button
                  variant="success"
                  class="mr-1"
                  size="sm"
                  @click="openShow(data.item.mainBody)"
              >{{ $t("admin.show") }}</b-button
              >
              <b-button
                  variant="primary"
                  class="mr-1"
                  size="sm"
                  :to="'/admin/faq-main-categories/' + selectedFaqMainCategoryId + '/faq-categories/'+selectedFaqCategoryId+'/faq/'+data.item.id+'/edit'"
              >{{ $t("admin.edit") }}</b-button
              >
              <b-button
                  v-if="!data.item.isDeleted"
                  @click.prevent="del(data.item)"
                  variant="danger"
                  class="mr-1"
                  size="sm"
              >{{ $t("admin.delete") }}</b-button
              >
              <b-button
                  v-else
                  @click.prevent="restore(data.item)"
                  variant="warning"
                  class="mr-1"
                  size="sm"
              >{{ $t("admin.restore") }}</b-button
              >
            </div>
          </template>
        </b-table>
      </b-row>
      <b-row class="text-center m-3 d-block" v-if="total > 0">
        <b-pagination
            align="center"
            v-model="page"
            :total-rows="total"
            :per-page="perPage"
            aria-controls="table"
        ></b-pagination>
      </b-row>
      <b-modal
          v-model="modalShow"
          id="showFaq"
          centered
          size="xl"
          :title="$t('admin.show-faq')"
      >
        <div v-html="showBody"></div>
      </b-modal>
    </b-col>
  </b-row>
</template>
<script>
import Faq from "../../../models/Faq";

export default {
  data: function () {
    return {
      q: "",
      page: 1,
      total: 0,
      perPage: 1,
      fields: [
        { key: "id", label: this.$t("admin.id") },
        { key: "title", label: this.$t("admin.title") },
        { key: "actions", label: this.$t("admin.actions"), tdClass: "full" },
      ],
      modalShow: false,
      showBody: ""
    };
  },
  watch: {
    selectedFaqMainCategoryId() {
      this.reset();
    },
    selectedFaqCategoryId() {
      this.reset();
    },
  },
  computed: {
    selectedFaqMainCategoryId() {
      return this.$route.params.faqMainCategory;
    },
    selectedFaqCategoryId() {
      return this.$route.params.faqCategory;
    },
  },
  methods: {
    reset() {
      this.page = 1;
      this.$refs.table.refresh();
    },
    openShow(body){
      this.showBody = body;
      this.modalShow = true;
    },
    loadFaq(ctx, callback) {
      let url = "/faq-main-categories/" + this.selectedFaqMainCategoryId +"/faq-categories/" + this.selectedFaqCategoryId +"/faqs";
      url += "?page=" + ctx.currentPage + "&q=" + this.q;
      this.$aGet(
          url,
          (data) => {
            let faqs = [];
            for (let obj of data.data) {
              const faq = new Faq(obj);
              faqs.push(faq);
            }
            this.total = data.total;
            this.perPage = data.per_page;
            callback(faqs);
          },
          (errors) => {
            console.log(errors);
          }
      );
    },
    del(faq) {
      this.$aPost(
          "/faq-main-categories/" + this.selectedFaqMainCategoryId +"/faq-categories/" + this.selectedFaqMainCategoryId+"/faqs/" + faq.id,
          {
            _method: "DELETE",
          },
          () => {
            this.$refs.table.refresh();
          },
          (errors) => {
            console.log(errors);
          }
      );
    },
    restore(faq) {
      this.$aPost(
          "/faq-main-categories/" + this.selectedFaqMainCategoryId +"/faq-categories/" + this.selectedFaqMainCategoryId+"/faqs/" + faq.id + "/restore",
          {},
          () => {
            this.$refs.table.refresh();
          },
          (errors) => {
            console.log(errors);
          }
      );
    },
  },
};
</script>
