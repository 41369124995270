import {marked} from 'marked';
export default class Faq {

    id = 0;
    title = null;
    mainTitle = '';
    body = null;
    mainBody = '';
    isDeleted = false;
    faqCategoryId = 0;

    constructor(data) {
        if (!data) {
            return;
        }
        this.id = data.id;
        this.title = data.title;
        this.mainTitle = data.title[process.env.VUE_APP_I18N_LOCALE];
        this.body = data.body;
        this.mainBody = marked(data.body[process.env.VUE_APP_I18N_LOCALE], { sanitize: true });
        this.isDeleted = data.deleted_at !== null;
        this.faqCategoryId = data.faq_category_id;
    }
}