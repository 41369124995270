import { render, staticRenderFns } from "./PagePayments.vue?vue&type=template&id=239ffe31&scoped=true&"
import script from "./PagePayments.vue?vue&type=script&lang=js&"
export * from "./PagePayments.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "239ffe31",
  null
  
)

export default component.exports