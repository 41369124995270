import { render, staticRenderFns } from "./PageFaqEdit.vue?vue&type=template&id=7aa02514&scoped=true&"
import script from "./PageFaqEdit.vue?vue&type=script&lang=js&"
export * from "./PageFaqEdit.vue?vue&type=script&lang=js&"
import style0 from "./PageFaqEdit.vue?vue&type=style&index=0&id=7aa02514&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7aa02514",
  null
  
)

export default component.exports