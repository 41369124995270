<template>
  <b-row class="verification" v-if="this.$route.params.id === undefined || verification !== null ">
    <b-col>
      <b-row class="border-bottom sticky-top bg-white page-header">
        <div class="d-flex align-items-center w-100 mx-3">
          <b-link @click="$router.go(-1)">
            <i class="bi-arrow-left" />
          </b-link>
          <h5 class="text-uppercase p-0 my-3 mx-2 flex-grow-1">
            {{ $t("general.verification") }}
          </h5>
          <b-button
            @click.prevent="unedit"
            variant="secondary"
            v-if="verification && editing"
            class="mr-2"
            >{{ $t("general.cancel") }}</b-button
          >
          <b-button @click.prevent="action" variant="primary">{{
            !editing ? $t("general.edit") : $t("general.save")
          }}</b-button>
        </div>
      </b-row>
      <b-row>
        <b-form class="w-100 mx-3 mt-3">
          <b-alert show variant="secondary" v-if="editing">{{
            $t("general.verification-info")
          }}</b-alert>
          <b-alert
            show
            :variant="
              localVerification && localVerification.isPending
                ? 'secondary'
                : 'danger'
            "
            v-if="!editing"
            >{{ $t("general.verification-status", [getStatus()]) }}</b-alert
          >
          <ui-form-input
            type="text"
            name="first_name"
            v-model="first_name"
            :errors="errors"
            :disabled="!editing"
            :label="$t('general.first-name')"
          />
          <ui-form-input
            type="text"
            name="last_name"
            v-model="last_name"
            :errors="errors"
            :disabled="!editing"
            :label="$t('general.last-name')"
          />

          <b-form-group
              :label="$t('general.type')"
              label-for="type"
              class="mb-3"
              label-class="small"
          >
            <b-form-select
                id="country"
                v-model="type"
                required
                :disabled="!editing"
                :options="types"
                :state="errors['type'] ? false : null"
                aria-describedby="country-errors"
            />
            <b-form-invalid-feedback id="country-errors">
              <div v-for="error in errors['type']" :key="error">
                {{ error }}
              </div>
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            :label="$t('general.country')"
            label-for="country"
            class="mb-3"
            label-class="small"
          >
            <b-form-select
              id="country"
              v-model="country"
              required
              :disabled="!editing"
              :options="countries"
              :state="errors['country'] ? false : null"
              aria-describedby="country-errors"
            />
            <b-form-invalid-feedback id="country-errors">
              <div v-for="error in errors['country']" :key="error">
                {{ error }}
              </div>
            </b-form-invalid-feedback>
          </b-form-group>

          <ui-form-input
            type="text"
            name="address"
            :disabled="!editing"
            v-model="address"
            :errors="errors"
            :label="$t('general.address')"
          />

          <ui-form-input
            type="text"
            name="city"
            :disabled="!editing"
            v-model="city"
            :errors="errors"
            :label="$t('general.city')"
          />

          <ui-form-input
            type="text"
            name="state"
            :disabled="!editing"
            v-model="state"
            :errors="errors"
            :label="$t('general.state')"
          />
          <ui-form-input
            type="text"
            name="zip"
            :disabled="!editing"
            v-model="zip"
            :errors="errors"
            :label="$t('general.zip')"
          />
          <ui-form-input
              type="text"
              name="socialMediaLink"
              :disabled="!editing"
              v-model="socialMediaLink"
              :errors="errors"
              :label="$t('general.social-media-link')"
          />

          <b-form-group
            :label="$t('general.photo-id')"
            label-for="photo1"
            class="mb-3"
            label-class="small"
          >
            <b-form-file
              id="photo1"
              v-model="photo1"
              :disabled="!editing"
              :state="errors['photo1'] ? false : null"
              required
              aria-describedby="photo1-errors"
            />
            <b-form-invalid-feedback id="photo1-errors'">
              <div v-for="error in errors['photo1']" :key="error">
                {{ error }}
              </div>
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            :label="$t('general.photo-id')"
            label-for="photo2"
            class="mb-3"
            label-class="small"
          >
            <b-form-file
              id="photo2"
              v-model="photo2"
              :disabled="!editing"
              :state="errors['photo2'] ? false : null"
              required
              aria-describedby="photo2-errors"
            />
            <b-form-invalid-feedback id="photo2-errors'">
              <div v-for="error in errors['photo2']" :key="error">
                {{ error }}
              </div>
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
              :label="$t('general.photo-id')"
              label-for="phot3"
              class="mb-3"
              label-class="small"
          >
            <b-form-file
                id="photo3"
                v-model="photo3"
                :disabled="!editing"
                :state="errors['photo2'] ? false : null"
                required
                aria-describedby="photo3-errors"
            />
            <b-form-invalid-feedback id="photo3-errors'">
              <div v-for="error in errors['photo3']" :key="error">
                {{ error }}
              </div>
            </b-form-invalid-feedback>

            <b-form-group
                :label="$t('general.photo-id')"
                label-for="photo4"
                class="mb-3"
                label-class="small"
            >
              <b-form-file
                  id="photo4"
                  v-model="photo4"
                  :disabled="!editing"
                  :state="errors['photo4'] ? false : null"
                  required
                  aria-describedby="photo4-errors"
              />
              <b-form-invalid-feedback id="photo4-errors'">
                <div v-for="error in errors['photo4']" :key="error">
                  {{ error }}
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
          </b-form-group>
          <div v-if="!editing">
            <i class="bi-file-check" /> {{ $t("general.file-uploaded") }}
          </div>
        </b-form>
      </b-row>
    </b-col>
  </b-row>
</template>
<script>
import Verification from "../models/Verification";
import UiFormInput from "../ui/UiFormInput.vue";
import Countries from "../helpers/Countries";
export default {
  props: {
  },
  data: function () {
    return {
      verification: null,
      errors: {},
      localVerification: this.verification,
      first_name: this.verification
          ? this.verification.info.first_name
          : this.$store.state.currentUser.name,
      last_name: this.verification ? this.verification.info.last_name : "",
      type: this.verification ? this.verification.type : null,
      country: this.verification ? this.verification.country : null,
      address: this.verification ? this.verification.info.address : "",
      city: this.verification ? this.verification.info.city : "",
      state: this.verification ? this.verification.info.state : "",
      zip: this.verification ? this.verification.info.zip : "",
      socialMediaLink: this.verification ? this.verification.info.social_media_link : "",
      photo1: null,
      photo2: null,
      photo3: null,
      photo4: null,
      editing: this.verification == null,
      types: [{'value': 'own', 'text': this.$t('general.own')}, {'value': 'external', 'text': this.$t('general.external')}]
    };
  },
  components: {
    UiFormInput,
  },
  computed: {
    countries() {
      let countries = [];
      for (let k in Countries) {
        countries.push({
          value: k,
          text: Countries[k],
        });
      }
      return countries;
    },
  },
  created() {
    if(this.$route.params.id !== 'undefined'){
      this.$get(
          "/verification/"+this.$route.params.id+"/show",
          (data) => {
            this.verification = data.verification;
            this.localVerification = this.verification;
            this.first_name = this.verification.info.first_name;
            this.last_name = this.verification.info.last_name;
            this.type = this.verification.type;
            this.country =this.verification.country;
            this.address = this.verification.info.address;
            this.city = this.verification.info.city;
            this.state = this.verification.info.state;
            this.zip = this.verification.info.zip;
            this.socialMediaLink = this.verification.info.social_media_link;
            this.editing = false;
          },
          (errors) => {
            console.log(errors);
          }
      );
    }
  },
  mounted() {
    this.setCountry();
  },
  methods: {
    setCountry() {
      if (!this.country) {
        const lang = navigator.language;
        let country =
          lang.length == 5
            ? lang.substring(3).toUpperCase()
            : lang.toUpperCase();
        for (let c of this.countries) {
          if (c.value == country) {
            this.country = country;
            break;
          }
        }
      }
    },
    getStatus() {
      if (!this.localVerification) {
        return "";
      }
      switch (this.localVerification.status) {
        case Verification.STATUS_PENDING:
          return this.$t("general.pending");
        case Verification.STATUS_DECLINED:
          return this.$t("general.declined");
      }
    },
    unedit() {
      this.editing = false;
    },
    action() {
      if (!this.editing) {
        this.editing = true;
      } else {
        this.errors = {};
        let formData = new FormData();
        formData.append("country", this.country);
        formData.append("type", this.type);
        formData.append("first_name", this.first_name);
        formData.append("last_name", this.last_name);
        formData.append("address", this.address);
        formData.append("city", this.city);
        formData.append("state", this.state);
        formData.append("zip", this.zip);
        formData.append("social_media_link", this.socialMediaLink);
        formData.append("photo1", this.photo1);
        formData.append("photo2", this.photo2);
        formData.append("photo3", this.photo3);
        formData.append("photo4", this.photo4);
        this.$post(
          "/verification",
          formData,
          (data) => {
            this.localVerification = new Verification(data);
            this.editing = false;
          },
          (errors) => {
            this.errors = errors;
          }
        );
      }
    },
  },
};
</script>
