<template>
  <b-row>
    <b-col>
      <b-row class="border-bottom sticky-top page-header bg-white">
        <div class="d-flex align-items-center w-100 mx-3">
          <h5 class="text-uppercase p-0 my-3 flex-grow-1">
            {{ $t("general.verification") }}
          </h5>
          <b-nav pills>
            <b-nav-item >
              <b-button
                  style="color: white;"
                  :to="'/verifications/new'"
                  variant="success">
                {{$t('admin.add-new')}}
              </b-button>
            </b-nav-item>
          </b-nav>
        </div>
      </b-row>
      <b-row>
        <b-table
            id="table"
            hover
            ref="table"
            responsive
            :items="loadVerifications"
            :fields="fields"
            per-page="0"
            class="small nowrap"
            :current-page="page"
        >
          <template #cell(status)="data">
            <span>{{$t('general.'+data.item.status)}}</span>
          </template>
          <template #cell(type)="data">
            <span>{{$t('general.'+data.item.type)}}</span>
          </template>
          <template #cell(actions)="data">
            <div >
              <b-button
                  variant="success"
                  class="mr-1"
                  size="sm"
                  :to="'/verifications/show/'+data.item.id"
              >{{ $t("admin.show") }}</b-button
              >
            </div>
          </template>
        </b-table>
      </b-row>
      <b-row class="text-center m-3 d-block" v-if="total > 0">
        <b-pagination
            align="center"
            v-model="page"
            :total-rows="total"
            :per-page="perPage"
            aria-controls="table"
        ></b-pagination>
      </b-row>
    </b-col>
  </b-row>
</template>
<script>
import Verification from "../models/Verification";

export default {
  data: function () {
    return {
      q: "",
      page: 1,
      total: 0,
      perPage: 1,
      fields: [
        { key: "name", label: this.$t("general.name") },
        { key: "status", label: this.$t("admin.status") },
        { key: "type", label: this.$t("general.type") },
        { key: "actions", label: this.$t("admin.actions"), tdClass: "full" },
      ]
    };
  },
  watch: {

  },
  computed: {

  },
  methods: {
    reset() {
      this.page = 1;
      this.$refs.table.refresh();
    },
    loadVerifications(ctx, callback) {
      let url = "/verification/list";
      url += "?page=" + ctx.currentPage + "&q=" + this.q;

      this.$get(
          url,
          (data) => {
            let verifications = [];
            for (let obj of data.data) {
              const verification = new Verification(obj);
              verifications.push(verification);
            }
            this.total = data.total;
            this.perPage = data.per_page;
            callback(verifications);
          },
          (errors) => {
            console.log(errors);
          }
      );
    },
  },
};
</script>
