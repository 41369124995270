import Vue from 'vue'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import axios from 'axios'
import UniversalSocialauth from 'universal-social-auth'

import App from './App.vue'
import { BootstrapVue } from 'bootstrap-vue'
import VueClipboard from 'vue-clipboard2'
import VueMask from 'v-mask'
import Misc from './plugins/Misc'

import "@/assets/scss/_theming.scss";
import i18n from './i18n'

import routes from './components/helpers/Router';
const router = new VueRouter({
  routes,
  mode: 'history'
});

Vue.use(Vuex)
import stores from './components/helpers/Store';
import './registerServiceWorker'
const store = new Vuex.Store(stores);

Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.use(BootstrapVue)
Vue.use(VueClipboard)
Vue.use(Misc);
Vue.use(VueMask);

const options = {
  providers: {
    google: {
      name: 'google',
      url: '/auth/google',
      authorizationEndpoint: 'https://accounts.google.com/o/oauth2/auth',
      clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
      clientSecret: process.env.VUE_APP_GOOGLE_CLIENT_SECRET,
      redirectUri: process.env.VUE_APP_GOOGLE_RETURN_URL,
      requiredUrlParams: ['scope'],
      optionalUrlParams: ['display'],
      scope: ['profile', 'email'],
      scopePrefix: 'openid',
      scopeDelimiter: ' ',
      display: 'popup',
      oauthType: '2.0',
      popupOptions: { width: 452, height: 633 }
    }
  }
}

const Oauth = new UniversalSocialauth(axios, options)

Vue.prototype.$axios = axios
Vue.prototype.$Oauth = Oauth

new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
